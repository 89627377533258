import {
    AngleCard,
    AngleCollectionSection,
    Author,
    Award,
    BrandCard,
    CallToAction,
    CardIcon,
    Carousel,
    ColumnsSection,
    ContactOffice,
    ContactPoint,
    ContentWidget,
    CookieSection,
    Document,
    DocumentList,
    EmbeddedIcon,
    EmbeddedImage,
    ErrorBoundary,
    FaqCollection,
    FeaturedCard,
    FundsOverview,
    Hero,
    HeroCarousel,
    HighlightedArticleSection,
    HrInfoCard,
    IconNumberCard,
    Iframe,
    Image,
    ImageSectionWithOverlay,
    JobCard,
    LabelWIcon,
    LatestArticlesSection,
    Map,
    MediaGridSection,
    NewsCard,
    NewsCollectionSection,
    NumberCard,
    OptionalSection,
    OverlapSection,
    Podcast,
    Quote,
    ReportHighlight,
    RichText,
    Section,
    ServiceCard,
    SimpleNewsCard,
    SplitSection,
    SubAssetCard,
    Subtitle,
    SustainabilityCard,
    SwitchInvestorTypeSection,
    Tabs,
    ThumbnailLink,
    TimelineSection,
    ValuesCard,
    Video,
} from "@components";
import { CONTENT_TYPES } from "@constants";
import { ContactForm } from "@containers";
import { getInvestorSpecificContent } from "@utils/investors";
import React from "react";

export const shortenTypename = (entry: any) =>
    entry.__typename
        ?.replace("ContentfulContent", "Contentful")
        .replace("Assembly", "");

export const isFirstSection = (item, idx) =>
    idx === 0 && shortenTypename(item) === CONTENT_TYPES.SECTION;

export const matchesType = (entryType: any, typenames: string | string[]) => {
    if (!entryType || !typenames) return false;
    if (typeof typenames === "string")
        return entryType.toLowerCase() === typenames.toLowerCase();
    return typenames.some(
        typename => typename.toLowerCase() === entryType.toLowerCase(),
    );
};

/* eslint-disable sonarjs/cognitive-complexity */
const mapper = (entry: any, additionalProps?: any, index?: number | string) => {
    if (!entry) return;

    const typename = shortenTypename(entry);

    if (entry) {
        if (matchesType(typename, CONTENT_TYPES.CONTENT_WIDGET)) {
            return (
                <ContentWidget
                    basePath={additionalProps?.basePath}
                    type={entry.type}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.SECTION)) {
            return (
                <Section
                    basePath={additionalProps?.basePath}
                    data={entry}
                    isFirstSection={additionalProps?.isFirstSection}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.TIMELINE_SECTION)) {
            return <TimelineSection data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.VIDEO)) {
            return <Video data={entry} options={additionalProps} />;
        }
        if (matchesType(typename, CONTENT_TYPES.SUSTAINABILITY_CARD)) {
            return (
                <SustainabilityCard
                    cardMeasurements={additionalProps.cardMeasurements}
                    data={entry}
                    ref={additionalProps.ref}
                    shadowDom={additionalProps.shadowDom}
                />
            );
        }
        if (
            [CONTENT_TYPES.HERO, CONTENT_TYPES.HERO_PREVIEW].includes(typename)
        ) {
            return <Hero data={entry} {...additionalProps} />;
        }
        if (matchesType(typename, CONTENT_TYPES.HERO_CAROUSEL)) {
            if (!entry?.articles && !entry?.articles?.items.length) return null;

            return <HeroCarousel data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.EMBEDD_IMAGE)) {
            return <EmbeddedImage data={entry} options={additionalProps} />;
        }
        if (matchesType(typename, CONTENT_TYPES.IMAGE)) {
            return <Image {...entry} options={additionalProps} />;
        }
        if (matchesType(typename, CONTENT_TYPES.COLUMNS_SECTION)) {
            return <ColumnsSection data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.OVERLAPPING_SPLIT_SECTION)) {
            return <OverlapSection data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.SPLIT_SECTION)) {
            return <SplitSection data={entry} />;
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.NEWS_CARD,
                CONTENT_TYPES.NEWS_PAGE,
            ])
        ) {
            return <NewsCard data={entry} options={additionalProps} />;
        }
        if (matchesType(typename, CONTENT_TYPES.NEWS_CARD_PREVIEW)) {
            return (
                <NewsCard
                    data={entry}
                    options={additionalProps}
                    preview={true}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.CALL_TO_ACTION)) {
            return <CallToAction data={entry} options={additionalProps} />;
        }
        if (matchesType(typename, CONTENT_TYPES.EMBEDD_CTA)) {
            return (
                <CallToAction
                    data={{ ...entry.cta, variation: entry.variation }}
                    options={additionalProps}
                />
            );
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.RICHTEXT,
                CONTENT_TYPES.RICHTEXT_PREVIEW,
            ])
        ) {
            return (
                <RichText
                    options={{
                        ...additionalProps,
                        listIcon: entry.unorderedListIcon?.icon,
                    }}
                    textNode={entry}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.CAROUSEL)) {
            return <Carousel data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.SUB_ASSET_CARD)) {
            return <SubAssetCard data={entry} options={additionalProps} />;
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.ANGLE_CARD,
                CONTENT_TYPES.ANGLE_CARD_PREVIEW,
            ])
        ) {
            return (
                <AngleCard
                    data={entry}
                    options={additionalProps}
                    showIntroText={additionalProps?.showIntroText}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.SIMPLE_NEWS_CARD)) {
            if (!getInvestorSpecificContent(additionalProps?.investor, entry))
                return null;
            return (
                <SimpleNewsCard
                    data={entry}
                    key={`${entry.id}-${index}`}
                    options={additionalProps}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.SIMPLE_NEWS_CARD_PREVIEW)) {
            return (
                <SimpleNewsCard
                    data={entry}
                    key={`${entry.id}-${index}`}
                    preview={true}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.ANGLE_COLLECTION_SECTION)) {
            return (
                <AngleCollectionSection
                    data={entry}
                    key={`${entry.id}-${index}`}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.NEWS_COLLECTION_SECTION)) {
            return (
                <NewsCollectionSection
                    data={entry}
                    key={`${entry.id}-${index}`}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.THUMBNAIL_LINK)) {
            return <ThumbnailLink data={entry} key={`${entry.id}-${index}`} />;
        }
        if (matchesType(typename, CONTENT_TYPES.THUMBNAIL_LINK_PREVIEW)) {
            return <ThumbnailLink data={entry} key={entry.id} />;
        }
        if (matchesType(typename, CONTENT_TYPES.MAP)) {
            return <Map data={entry} key={entry.id} />;
        }
        if (matchesType(typename, CONTENT_TYPES.FORM)) {
            return (
                <ContactForm
                    data={entry}
                    key={entry.id}
                    options={additionalProps}
                />
            );
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.CONTACT_POINT,
                CONTENT_TYPES.CONTACT_POINT_PREVIEW,
            ])
        ) {
            return (
                <ContactPoint
                    data={entry}
                    key={entry.id}
                    options={additionalProps}
                />
            );
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.CONTACT_OFFICE,
                CONTENT_TYPES.CONTACT_OFFICE_PREVIEW,
            ])
        ) {
            return <ContactOffice data={entry} />;
        }

        if (
            matchesType(typename, [
                CONTENT_TYPES.AUTHOR,
                CONTENT_TYPES.AUTHOR_PREVIEW,
            ])
        ) {
            return <Author data={entry} />;
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.LABEL_W_ICON,
                CONTENT_TYPES.LABEL_W_ICON_PREVIEW,
            ])
        ) {
            return <LabelWIcon data={entry} options={additionalProps} />;
        }
        if (
            matchesType(typename, CONTENT_TYPES.DOCUMENT_COLUMN) &&
            additionalProps.columnSection
        ) {
            return <Document data={{ ...entry }} />;
        }
        if (matchesType(typename, CONTENT_TYPES.DOCUMENT_LIST)) {
            return <DocumentList data={{ ...entry }} />;
        }
        if (matchesType(typename, CONTENT_TYPES.COOKIE_SECTION)) {
            return <CookieSection data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.REPORT_HIGHLIGHT)) {
            return <ReportHighlight data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.HIGHLIGHTED_ARTICLE)) {
            return <HighlightedArticleSection data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.ICON_NUMBER_CARD)) {
            return (
                <IconNumberCard data={{ ...entry }} options={additionalProps} />
            );
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.BRAND,
                CONTENT_TYPES.BRAND_PREVIEW,
            ])
        ) {
            return <BrandCard data={entry} />;
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.AWARD,
                CONTENT_TYPES.AWARD_PREVIEW,
            ])
        ) {
            return <Award data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.LATEST_ARTICLES_SECTION)) {
            return <LatestArticlesSection data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.SUBTITLE)) {
            return <Subtitle data={entry} options={additionalProps} />;
        }
        if (matchesType(typename, CONTENT_TYPES.SWITCH_INVESTOR_TYPE_SECTION)) {
            return (
                <SwitchInvestorTypeSection
                    data={entry}
                    onClose={additionalProps?.onClose}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.TABS)) {
            return <Tabs data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.FUNDS_OVERVIEW)) {
            return <FundsOverview data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.NUMBERCARD)) {
            return <NumberCard data={{ ...entry }} />;
        }
        if (matchesType(typename, CONTENT_TYPES.VALUESCARD)) {
            return <ValuesCard data={{ ...entry }} options={additionalProps} />;
        }
        if (matchesType(typename, CONTENT_TYPES.SERVICECARD)) {
            return <ServiceCard data={{ ...entry }} />;
        }
        if (matchesType(typename, CONTENT_TYPES.HR_INFO_CARD)) {
            return <HrInfoCard data={entry} options={additionalProps} />;
        }
        if (
            matchesType(typename, [
                CONTENT_TYPES.IMAGE_WITH_OVERLAY_SECTION,
                CONTENT_TYPES.IMAGE_WITH_CTA_OVERLAY_SECTION,
            ])
        ) {
            return (
                <ImageSectionWithOverlay
                    data={entry}
                    options={additionalProps}
                />
            );
        }
        if (matchesType(typename, CONTENT_TYPES.FEATURED_CARD)) {
            return <FeaturedCard data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.JOB_CARDS)) {
            return <JobCard data={entry} />;
        }
        if (matchesType(typename, CONTENT_TYPES.QUOTE)) {
            return <Quote data={entry} />;
        }

        if (matchesType(typename, CONTENT_TYPES.FAQ_COLLECTION)) {
            return <FaqCollection data={entry} />;
        }

        if (matchesType(typename, CONTENT_TYPES.EMBEDD_ICON)) {
            return (
                <EmbeddedIcon data={entry} inline={additionalProps?.inline} />
            );
        }

        if (matchesType(typename, CONTENT_TYPES.PODCAST)) {
            return <Podcast data={entry} />;
        }

        if (matchesType(typename, CONTENT_TYPES.MEDIA_GRID_SECTION)) {
            return <MediaGridSection data={entry} />;
        }

        if (matchesType(typename, CONTENT_TYPES.IFRAME)) {
            return <Iframe data={entry} />;
        }

        if (matchesType(typename, CONTENT_TYPES.OPTIONAL_SECTION)) {
            return <OptionalSection data={entry} />;
        }

        if (matchesType(typename, CONTENT_TYPES.CARD_ICON)) {
            return <CardIcon data={entry} />;
        }

        if (!typename) {
            throw new Error(
                `The component for the entry with id: ${entry?.sys?.id} is most likely already implemented, but not allowed as child of its parent. Or its type is missing.`,
            );
        }
        throw new Error(
            `Component with typename: ${typename} not yet integrated.`,
        );
    }
};

export const componentMapper = (
    entry: any,
    additionalProps?: any,
    index?: number | string,
) => {
    if (!entry) return;

    const typename = shortenTypename(entry);
    const componentName = typename?.replace("Contentful", "");

    return (
        <ErrorBoundary
            componentName={componentName}
            key={`${entry.id}-${index}`}
        >
            {mapper(entry, additionalProps, index)}
        </ErrorBoundary>
    );
};
